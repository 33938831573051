import React, { useRef, useEffect } from "react";
import hiddenVideo from '../videos/branding.mp4'
const Banner = ({ setBannerElements }) => {
  const websites = useRef();
  const apps = useRef();
  const branding = useRef();


  useEffect(() => {
    let elements = {
      websites: websites,
      apps: apps,
      branding: branding,
    };
    setBannerElements(elements);
  }, [setBannerElements]);
  return (
    <div className="banner-container">
      <div className="banner-inner">
        <div className="banner-items-container">
          <div className="hiddenVideo">
            <video src={hiddenVideo} loop muted autoPlay={true} preload="auto"/>
          </div>
          <div className="banner-item">
            <h1 ref={websites}>Websites</h1>
          </div>
          <div className="banner-item">
            <h1 ref={apps}>Apps</h1>
          </div>
          <div className="banner-item">
            <h1 ref={branding}>Branding</h1>
          </div>
          <div className="banner-item">
            <h1>Advertising</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
