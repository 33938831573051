import styled from "styled-components";

export const ContactStyles = styled.div`
  background-color: #fff;
  .contactCol {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .contactMain {
    padding: 100px 0px;
  }
  .contactText {
    color: #15141a;
    h2 {
      font-size: 8rem;
      font-weight: 900;
      line-height: 130px;
      max-width: 750px;
    }
    span {
      display: block;
      margin: 60px 0px 20px 0px;
      font-size: 3rem;
    }
    a {
      font-size: 3rem;
      color: #15141a;
    }
  }
  .menuMain {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
  .contactBtn {
    position: relative;
    overflow: hidden;
    outline: none;
    border-radius: 40px;
    background-color: #ceff00;
    font-family: inherit;
  }

  .default-btn,
  .hover-btn {
    background-color: #ceff00;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 25px 30px;
    border-radius: 50px;
    font-size: 20px;
    font-weight: 700;
    transition: all 0.3s ease;
  }

  .hover-btn {
    position: absolute;
    inset: 0;
    background-color: #ceff00;
    transform: translate(0%, 100%);
  }

  .default-btn,
  .hover-btn span {
    color: #000;
  }

  .contactBtn:hover .default-btn {
    transform: translate(0%, -100%);
  }

  .contactBtn:hover .hover-btn {
    transform: translate(0%, 0%);
  }
  .contactForm {
    width: 100%;
    max-width: 700px;
  }
  form {
    .inputCol {
      display: flex;
      .inputbox:first-child {
        margin-right: 20px;
      }
    }
    input,
    textarea {
      width: 100%;
      border: 1px solid rgba(21, 20, 26, 0.4);
      border-radius: 5px;
      margin-top: 15px;
      font-size: 2.25rem;
      color: #969696;
      padding: 40px;
    }
    textarea {
      height: 300px;
      resize: none;
    }
  }
  .inputbox {
    flex: 1;
    position: relative;
  }
  .inputbox input {
    width: 100%;
    padding: 40px;
    border: 1px solid rgba(21, 20, 26, 0.4);
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    color: #969696;
    font-size: 2.25rem;
    transition: 0.4s;
  }
  .inputbox textarea {
    width: 100%;
    padding: 40px;
    border: 1px solid rgba(21, 20, 26, 0.4);
    background-color: #fff;
    border-radius: 5px;
    outline: none;
    color: #969696;
    font-size: 2.25rem;
    transition: 0.4s;
  }
  .inputbox span {
    position: absolute;
    left: 5px;
    top: 48px;
    padding: 10px 0px 10px 30px;
    color: #969696;
    pointer-events: none;
    font-size: 1.1em;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    transition: 0.4s;
  }
  .inputbox input:valid ~ span,
  .inputbox input:focus ~ span {
    color: #000;
    transform: translateX(25px) translateY(-37px);
    font-size: 0.65em;
    padding: 0 10px;
    background-color: #fff;
    border-left: 1px solid #75e44f;
    border-right: 1px solid #75e44f;
    letter-spacing: 0.2em;
  }
  .inputbox textarea:valid ~ span,
  .inputbox textarea:focus ~ span {
    color: #000;
    transform: translateX(40px) translateY(-37px);
    font-size: 0.65em;
    padding: 0 10px;
    background-color: #fff;
    border-left: 1px solid #75e44f;
    border-right: 1px solid #75e44f;
    letter-spacing: 0.2em;
  }
  .inputbox input:valid,
  .inputbox input:focus {
    border: 1px solid #75e44f;
  }
  .inputbox textarea:valid,
  .inputbox textarea:focus {
    border: 1px solid #75e44f;
  }
  @media (max-width: 1440px) {
    .contactText {
      h2 {
        font-size: 6rem;
        line-height: 100px;
        max-width: 600px;
      }
      span {
        margin: 50px 0px 15px 0px;
        font-size: 2.5rem;
      }
      a {
        font-size: 2.4rem;
      }
    }
    .contactForm {
      max-width: 650px;
    }
    .inputbox input {
      padding: 30px;
    }
    .inputbox textarea {
      padding: 30px;
    }
  }
  @media (max-width: 1024px) {
    .contactText {
      h2 {
        font-size: 4rem;
        line-height: 70px;
      }
      span {
        margin: 40px 0px 15px;
        font-size: 2rem;
      }
      a {
        font-size: 1.8rem;
      }
    }
    .contactForm {
      max-width: 525px;
    }
    .inputbox input {
      padding: 20px;
    }
    .inputbox textarea {
      padding: 20px;
    }
    .inputbox span {
      top: 35px;
      font-size: 1rem;
    }
    form {
      textarea {
        height: 230px;
      }
    }
    .inputbox input:valid ~ span,
    .inputbox input:focus ~ span {
      transform: translateX(25px) translateY(-24px);
    }
    .inputbox textarea:valid ~ span,
    .inputbox textarea:focus ~ span {
      transform: translateX(40px) translateY(-24px);
    }
  }
  @media (max-width: 768px) {
    .contactCol {
      flex-direction: column;
      justify-content: center;
      height: 80vh;
    }
    .contactMain {
      padding: 0px 0px;
    }
    .contactText {
      h2 {
        font-size: 3rem;
        line-height: 55px;
        max-width: 400px;
      }
      span {
        margin: 20px 0px 10px;
        font-size: 1.5rem;
      }
      a {
        font-size: 1.2rem;
      }
    }
    form {
      margin-top: 10px;
    }
    .contactForm {
      max-width: 480px;
    }
    .inputbox input {
      padding: 10px;
    }
    .inputbox textarea {
      padding: 10px;
    }
    .inputbox span {
      top: 29px;
      font-size: 0.8rem;
      padding: 5px 0px 5px 15px;
    }
    form {
      textarea {
        height: 160px;
      }
    }
    .inputbox input:valid ~ span,
    .inputbox input:focus ~ span {
      transform: translateX(25px) translateY(-15px);
    }
    .inputbox textarea:valid ~ span,
    .inputbox textarea:focus ~ span {
      transform: translateX(40px) translateY(-15px);
    }
    .default-btn,
    .hover-btn {
      padding: 15px 20px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .footerTopMain {
    background-color: #000;
    padding: 50px 0px;
    margin: 20px 0px;
  }

  .footerTopContainer {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0px 20px;
  }
  .footerTopText p {
    text-align: center;
    font-size: 5rem;
    line-height: 100px;
    color: #fff;
    margin: 0 auto;
  }
  .input-container {
    margin-bottom: 30px;
    position: relative;
    width: 100%;
  }

  .input-container input[type="text"] {
    font-size: 20px;
    width: 100%;
    border: none;
    color: #fff;
    border-bottom: 2px solid #ccc;
    padding: 5px 0;
    background-color: transparent;
    outline: none;
  }

  .input-container .label {
    position: absolute;
    top: 0;
    left: 0;
    color: #ccc;
    transition: all 0.3s ease;
    pointer-events: none;
  }

  .input-container input[type="text"]:focus ~ .label,
  .input-container input[type="text"]:valid ~ .label {
    top: -20px;
    font-size: 16px;
    color: #333;
  }

  .input-container .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #333;
    transform: scaleX(0);
    transition: all 0.3s ease;
  }

  .input-container input[type="text"]:focus ~ .underline,
  .input-container input[type="text"]:valid ~ .underline {
    transform: scaleX(1);
  }
  .button {
    margin: 0;
    height: auto;
    background: transparent;
    padding: 0;
    border: none;
  }

  /* button styling */
  .button {
    --border-right: 6px;
    --animation-color: #ceff00;
    --fs-size: 1.2rem;
    letter-spacing: 3px;
    text-decoration: none;
    font-size: var(--fs-size);
    position: relative;
    color: #fff;
  }
  /* this is the text, when you hover on button */
  .hover-text {
    position: absolute;
    box-sizing: border-box;
    content: attr(data-text);
    color: var(--animation-color);
    width: 0%;
    inset: 0;
    border-right: var(--border-right) solid var(--animation-color);
    overflow: hidden;
    transition: 0.5s;
    -webkit-text-stroke: 1px var(--animation-color);
  }
  /* hover */
  .button:hover .hover-text {
    width: 100%;
    filter: drop-shadow(0 0 23px var(--animation-color));
  }
`;

export const FooterBottomStyles = styled.div`
  height: 100vh;
  position: relative;
  padding-bottom: 20px;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .videoBanner {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .videoBanner video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .videoBanner h1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    font-size: 25vw;
    font-weight: 800;
    letter-spacing: 10px;
    font-family: Blanka;
    user-select: none;
    color: #fff;
    text-align: center;
    line-height: 57vh;
    mix-blend-mode: multiply;
  }
  .footerSocial {
    width: 100%;
    min-width: 100%;
    padding: 0px 15px;
    background-color: #000;
    /* padding-bottom: 20px; */
  }
  .footerSocialCol {
    display: flex;
    justify-content: space-between;
    position: relative;
    div {
      font-size: 1.2rem;
      line-height: 24px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    ul {
      li {
        font-size: 1.2rem;
        color: #fff;
        max-width: 210px;
        line-height: 22px;
      }
    }
  }
  @media (max-width: 1440px) {
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    .footerSocialCol {
      flex-direction: column;
      div {
        font-size: 1.2rem;
        line-height: 24px;
        color: #fff;
        position: absolute;
        top: 97%;
        left: 75%;
        transform: translate(-50%, -50%);
      }
    }
    .FooterBottomMain {
      width: 100%;
      min-width: 100%;
    }
  }
  @media (max-width: 500px) {
    .footerSocialCol {
      div {
        font-size: 1rem;
        line-height: 15px;

        top: 97%;
        left: 65%;
      }
      ul {
        li {
          font-size: 1rem;
          color: #fff;
          line-height: 15px;
        }
        &:last-child {
          margin-top: 10px;
        }
      }
    }
  }
`;
