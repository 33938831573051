import styled from "styled-components";

export const ContainerMain = styled.div`
  max-width: 1700px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 1440px) {
    max-width: 1375px;
  }
  @media (max-width: 1024px) {
    max-width: 960px;
  }
  @media (max-width: 768px) {
    padding: 0px 15px;
  }
  @media screen and (max-width: 470px) {
    padding: 0;
  }
`;
