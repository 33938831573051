import styled from "styled-components";

export const ServiceStyled = styled.div`
  .lastContainer {
    padding: 30px 0px;
    position: relative;
    background-color: #000;
  }
  .serviceCol {
    display: flex;
  }
  .serviceDescCol {
    display: flex;
  }
  .serviceText h3 {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
  }
  .serviceText p {
    margin-top: 15px;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 400;
    max-width: 350px;
  }

  .weDone {
    margin-top: 180px;
    z-index: 1000;
    position: relative;
    background-color: #000;
  }
  .weDoneTitleCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }

  .weDone h3 {
    color: #fff;
    font-size: 3.5rem;
    font-weight: 900;
  }
  .smArrowBtn {
    position: relative;
    overflow: hidden;
    outline: none;
    background-color: #15141a;
    border-radius: 40px;
    font-family: inherit;
  }
  .arrow-default-btn,
  .arrow-hover-btn {
    background-color: #15141a;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 17px;
    font-weight: 400;
    border: 2px solid #ceff00;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }

  .arrow-hover-btn {
    position: absolute;
    inset: 0;
    background-color: #15141a;
    transform: translate(0%, 100%);
  }
  .smArrowBtn:hover .arrow-default-btn {
    transform: translate(0%, -100%);
  }

  .smArrowBtn:hover .arrow-hover-btn {
    transform: translate(0%, 0%);
  }
  .carouselInner {
    display: flex;
    align-items: baseline;
  }
  .carouselTextCol {
    display: flex;
    margin-top: 15px;
  }
  .swiper_container {
    height: 24rem;
    padding: 1rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 30rem;
    height: 17rem;
    position: relative;
  }
  .carouselName,
  .carouselTitle {
    color: #fff;
    font-size: 1.5rem;
    padding: 5px 15px;
  }
  .carouselName {
    border-right: 2px solid #fff;
  }
  .serviceSliderMain {
    display: none;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
  }
  .container .box {
    position: relative;
    width: 320px;
    height: 150px;
    margin: 20px;
    border-radius: 30px;
    transform-style: preserve-3d;
  }
  .container .box h3 {
    position: absolute;
    top: 20px;
    left: 40px;
    color: #fff;
    font-size: 1.4rem;
    width: 90%;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 75px);
    transition: 0.5s;
    opacity: 1;
    z-index: 10;
  }

  .container .box p {
    position: absolute;
    top: 55px;
    left: 40px;
    color: #fff;
    line-height: 19px;
    font-size: 1rem;
    width: 80%;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 75px);
    transition: 0.5s;
    opacity: 1;
    z-index: 10;
  }
  .serviceColTwo {
    margin-top: 40px;
  }
  .box1 {
    background: #29282e;
  }
  .box2 {
    background: #0042f3;
  }

  .box3 {
    background: #ffffff;
  }
  .box4 {
    background: #ceff00;
  }
  #serviceTextWhite {
    color: #000;
  }
  .subtitle {
    transform: translateY(160px);
    color: rgb(134, 110, 221);
    text-align: center;
    width: 100%;
  }

  .title {
    opacity: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out-out;
    transition-delay: 100ms;
    position: absolute;
    font-size: x-large;
    font-weight: bold;
  }
  @media (max-width: 1440px) {
    .container .box {
      width: 320px;
      height: 150px;
    }
    .container .box h3 {
      font-size: 1.4rem;
    }
    .container .box p {
      top: 55px;
      font-size: 1rem;
    }
    .serviceBigText span {
      &:nth-child(2) {
        margin-left: 0%;
      }
      &:nth-child(3) {
        margin-left: 0%;
      }
    }
    .serviceCol {
      justify-content: space-between;
    }
    .serviceBigText span {
      font-size: 7.5rem;
      line-height: 6.5rem;
    }
    .serviceBigText {
      margin-left: 50px;
      margin-top: 50px;
    }
    .weDone h3 {
      font-size: 3rem;
    }
    .swiper_container {
      height: 18rem;
      padding: 1rem 0;
      position: relative;
    }
    .swiper-slide {
      width: 24rem;
      height: 17rem;
      position: relative;
    }
  }
  @media (max-width: 1024px) {
    .lastContainer {
      padding-top: 0px;
    }
    .serviceBigText {
      margin-left: 30px;
      margin-top: 30px;
      span {
        font-size: 6rem;
        line-height: 5.5rem;
      }
    }
    .container .box {
      width: 270px;
      height: 160px;
    }
    .container .box h3 {
      top: 28px;
      left: 25px;
    }
    .container .box h3 {
      font-size: 1.1rem;
    }
    .container .box p {
      top: 53px;
      left: 25px;
      line-height: 18px;
      font-size: 0.9rem;
    }
    .weDone {
      margin-top: 50px;
    }
    .weDone h3 {
      font-size: 3rem;
    }
    .swiper_container {
      height: 22rem;
      padding: 1rem 0;
      position: relative;
    }
    .swiper-slide {
      width: 24rem;
      height: 14rem;
      position: relative;
    }
  }
  @media (max-width: 768px) {
    .serviceCol {
      flex-direction: column-reverse;
    }
    .serviceBigText span {
      font-size: 3rem;
      line-height: 3.5rem;
      display: inline;
    }
    .carouselName,
    .carouselTitle {
      color: #fff;
      font-size: 1rem;
      padding: 5px 10px;
    }
    .swiper_container {
      height: 15rem;
    }
    .swiper-slide {
      width: 17rem;
      height: 9rem;
      position: relative;
    }
    .weDone h3 {
      font-size: 2rem;
    }
    .arrow-default-btn,
    .arrow-hover-btn {
      padding: 5px 10px;
      font-size: 15px;
    }
    .serviceBigText span {
      &:last-child {
        -webkit-text-stroke: 1px #fff;
      }
    }
    .weDoneTitleCol {
      margin-bottom: 30px;
    }
  }
  @media (max-width: 500px) {
    .container {
      flex-direction: column;
    }
    .serviceColTwo {
      margin-top: 0px;
    }
    .weDone h3 {
      font-size: 1.5rem;
    }
    .arrow-default-btn,
    .arrow-hover-btn {
      padding: 5px 0px;
      font-size: 10px;
      span img {
        width: 60%;
      }
    }
  }
`;
