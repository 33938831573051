import React from "react";
import { ContactStyles, FooterBottomStyles } from "./styles/ContactStyled";
import { ContainerMain } from "./styles/Container";
import footerVideo from "../videos/textVideo.mp4";
const Contacts = () => {
  return (
    <>
      <ContactStyles>
        <ContainerMain>
          <div className="contactMain">
            <div className="contactCol">
              <div className="contactText">
                <h2 data-scroll data-scroll-speed="1">
                  Have something in mind?
                </h2>
                <span>Email us: </span>
                <a href="mailto:innowasiya-tilsimaty@gmail.com">
                  innowasiya-tilsimaty@gmail.com
                </a>
              </div>
              <div className="contactForm">
                <form action="">
                  <div className="inputCol">
                    <div className="inputbox">
                      <input type="text" required />
                      <span>Name</span>
                    </div>
                    <div className="inputbox">
                      <input type="text" required />
                      <span>Company</span>
                    </div>
                  </div>
                  <div className="inputbox">
                    <input type="text" required />
                    <span>Email</span>
                  </div>
                  <div className="inputbox">
                    <textarea type="text" required />
                    <span>Details</span>
                  </div>

                  <div className="menuMain">
                    <div className="contactBtn">
                      <div className="default-btn">
                        <span>Contact us</span>
                      </div>
                      <div className="hover-btn">
                        <span>Contact us</span>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </ContainerMain>
      </ContactStyles>
      <FooterBottomStyles>
        <div className="footerMainText">
          <div className="videoBanner">
            <video src={footerVideo} autoPlay={true} loop muted />
            <h1>INNTILL</h1>
          </div>
        </div>
        <div className="FooterBottomMain">
          <div className="footerSocial">
            <div className="footerSocialCol">
              <ul>
                <li>Tajirchilik merkezi, Ashgabat,Turkmenistan</li>
                <li>Credits</li>
                <li>Privacy Policy</li>
              </ul>

              <div>Innowasiyatilsimaty@gmail.com</div>

              <ul>
                <li>Projects</li>
                <li>Contact </li>
                <li>Career</li>
                <li>English</li>
              </ul>
            </div>
          </div>
        </div>
      </FooterBottomStyles>
    </>
  );
};

export default Contacts;
