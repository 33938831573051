import React, { useEffect, useRef, useState } from "react";
import VanillaTilt from "vanilla-tilt";
import { ServiceStyled } from "./styles/ServiceStyles";
import job1 from "../assests/img/job1.png";
import smArrow from "../assests/img/smArrow.png";
import { ContainerMain } from "./styles/Container";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import {
  ServicesParallax,
  ServiceParallaxRes,
} from "./styles/ServicesParallaxStyles";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Section from "./Section/Section";
import aniVideo from "../videos/ani.mp4";
import brandingImg from "../assests/img/branding.png";
import uxImg from "../assests/img/ux.png";
import webImg from "../assests/img/web.png";

const Main = () => {
  const divRef = useRef(null);
  const swiperRef = useRef();
  const [isSmallerScreen, setIsSmallerScreen] = useState(false);

  const parallaxRef = useRef();

  const onScroll = () =>
    console.log(parallaxRef.current.current / parallaxRef.current.space);

  useEffect(() => {
    if (!parallaxRef.current || !parallaxRef.current.container) return;
    parallaxRef.current.container.onscroll = onScroll;
  });

  useEffect(() => {
    VanillaTilt.init(divRef.current, {
      max: 25,
      speed: 350,
    });

    if (window.innerWidth < 1200) {
      setIsSmallerScreen(true);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1200) {
        setIsSmallerScreen(true);
      } else {
        setIsSmallerScreen(false);
      }
    });

    //It also supports NodeList
    VanillaTilt.init(document.querySelectorAll(".box"));

    return window.removeEventListener("resize", () => {});
  }, []);

  const doneJobs = [
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
    {
      title: "Landing Page",
      name: "Zamana Teklibi",
      img: job1,
    },
  ];
  return (
    <ServiceStyled>
      <div className="lastContainer">
        <ContainerMain>
          {!isSmallerScreen ? (
            <ServicesParallax id="ser-par">
              <div
                className="serviceBigText"
                data-scroll
                data-scroll-sticky
                data-scroll-target="#ser-par"
                data-scroll-repeat
              >
                <div>ser</div>
                <div>vic</div>
                <div>es</div>
              </div>
              <div className="videos">
                <Section
                  title={"UX/UI DESIGN"}
                  definition={
                    "Put your business forward from your competitors with a unique and stunning design, which will help increase leads and sales."
                  }
                  img={uxImg}
                />
                <Section
                  title={"WEB DEVELOPMENT"}
                  definition={`Need something that works perfectly on every device?
                              Need something looks good ? I can take your design
                              or create my own.`}
                  img={webImg}
                />
                <Section
                  title={"BRANDING"}
                  definition={`Need something that works perfectly on every device?
                            Need something looks good ? I can take your design
                            or create my own.`}
                  img={brandingImg}
                />
                <Section
                  last={"last-child"}
                  title={"ANIMATION"}
                  definition={`My design services can help you achieve a memorable
                            and powerful first impression for your visitors.`}
                  video={aniVideo}
                />
              </div>
            </ServicesParallax>
          ) : (
            <ServiceParallaxRes>
              <div className="serviceBigText">
                <div>services</div>
              </div>
              <div className="servicesContainer">
                <Section
                  title={"UX/UI DESIGN"}
                  definition={
                    "Put your business forward from your competitors with a unique and stunning design, which will help increase leads and sales."
                  }
                  img={uxImg}
                />
                <Section
                  title={"WEB DEVELOPMENT"}
                  definition={`Need something that works perfectly on every device?
                              Need something looks good ? I can take your design
                              or create my own.`}
                  img={webImg}
                />
                <Section
                  title={"BRANDING"}
                  definition={`Need something that works perfectly on every device?
                            Need something looks good ? I can take your design
                            or create my own.`}
                  img={brandingImg}
                />
                <Section
                  title={"ANIMATION"}
                  definition={`My design services can help you achieve a memorable
                            and powerful first impression for your visitors.`}
                  video={aniVideo}
                />
              </div>
            </ServiceParallaxRes>
          )}
          <div className="weDone">
            <div className="weDoneTitleCol">
              <div>
                <h3>What we've done</h3>
              </div>
              <div>
                <div className="menuMain">
                  <button
                    onClick={() => swiperRef.current?.slideNext()}
                    className="smArrowBtn"
                  >
                    <div className="arrow-default-btn">
                      <span>
                        <img src={smArrow} alt="" />
                      </span>
                    </div>
                    <div className="arrow-hover-btn">
                      <span>
                        <img src={smArrow} alt="" />
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="weDoneCarousel">
              <div className="carouselInner">
                <Swiper
                  effect={"coverflow"}
                  centeredSlides={true}
                  slidesPerView={"auto"}
                  loop={true}
                  pagination={{
                    dynamicBullets: true,
                    clickable: true,
                  }}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                  }}
                  modules={[EffectCoverflow, Pagination, Navigation]}
                  onBeforeInit={(swiper) => {
                    swiperRef.current = swiper;
                  }}
                  className="swiper_container"
                >
                  {doneJobs.map((item, slideContent) => {
                    return (
                      <SwiperSlide key={slideContent}>
                        <div className="carouselItems">
                          <div className="carouselImg">
                            <img src={item.img} alt="jobsWeDone" />
                          </div>
                          <div className="carouselTextCol">
                            <div className="carouselName">{item.name}</div>
                            <div className="carouselTitle">{item.title}</div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </ContainerMain>
      </div>
    </ServiceStyled>
  );
};

export default Main;
