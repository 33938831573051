import React, { useState } from "react";
import { ContainerMain } from "./styles/Container";
import "../style.scss";
const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  if (click) {
    document.body.classList.add("active");
  } else {
    document.body.classList.remove("active");
  }
  return (
    <header>
      <ContainerMain>
      <div className="header-inner">
        <div className="header-inner-logo">
          <h3>INNTILL</h3>
        </div>
        <div className="header-inner-nav">
          <div className="headerBtn">
            <button
              onClick={handleClick}
              className={click ? "burger burger2 active" : "burger burger2"}
            >
              <span></span>
            </button>
            <div className={click ? "navbar active" : "navbar"}>
              <div className="navbarColMain">
                <ul className="navbarSocial">
                  <span>Social</span>
                  <li className="navSocialItem">
                    <a href="">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Instagram</span>
                        </div>
                        <div className="hover-btn">
                          <span>Instagram</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navSocialItem">
                    <a href="">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Behance</span>
                        </div>
                        <div className="hover-btn">
                          <span>Behance</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navSocialItem">
                    <a href="">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Dribble</span>
                        </div>
                        <div className="hover-btn">
                          <span>Dribble</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <div className="navSocialMail">
                    <a href="mailto:innowasiya-tilsimaty@gmail.com">
                      innowasiya-tilsimaty@gmail.com
                    </a>
                  </div>
                </ul>
                <ul className="navbarCol">
                  <span>Menu</span>
                  <li className="navbarItem">
                    <a onClick={() => setClick(false)} href="#about">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Projects</span>
                        </div>
                        <div className="hover-btn">
                          <span>Projects</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navbarItem">
                    <a onClick={() => setClick(false)} href="#mission">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>About us</span>
                        </div>
                        <div className="hover-btn">
                          <span>About us</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navbarItem">
                    <a onClick={() => setClick(false)} href="#service">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Career</span>
                        </div>
                        <div className="hover-btn">
                          <span>Career</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navbarItem">
                    <a onClick={() => setClick(false)} href="#logistics">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Contact</span>
                        </div>
                        <div className="hover-btn">
                          <span>Contact</span>
                        </div>
                      </div>
                    </a>
                  </li>
                  <li className="navbarItem">
                    <a onClick={() => setClick(false)} href="#topClients">
                      <div className="hoverMenu">
                        <div className="default-btn">
                          <span>Backstage</span>
                        </div>
                        <div className="hover-btn">
                          <span>Backstage</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </ContainerMain>
      
    </header>
  );
};

export default Header;
