import React, { useState, useEffect, useRef } from "react";
//HOOKS
import useMousePosition from "./components/hooks/useMousePosition";
//COMPONENTS
import Header from "./components/header";
import Banner from "./components/banner";
import Cursor from "./components/Cursor";
import Main from "./components/main";
import Contacts from "./components/Contacts";
import Footer from "./components/Footer";
//FUNCTION
import getCursorProps from "./getCursorProps";
//STYLE
import useLocomotiveScroll from "./components/hooks/useLocomotiveScroll.tsx";
import { gsap, Power2, Expo } from "gsap";
import "./components/styles/style.css";
export default function App() {
  const scrollRef = useRef(null);
  const [bannerElements, setBannerElements] = useState({});
  const mousePosition = useMousePosition();
  const [cursorProps, setCursorProps] = useState({
    videoID: null,
    scale: 1,
  });
  const [locomotiveRef] = useLocomotiveScroll({
    ref: scrollRef,
    smooth: true,
  });

  useEffect(() => {
    setCursorProps(getCursorProps(bannerElements, mousePosition));
  }, [bannerElements, mousePosition]);

  function fadeOut() {
    gsap.to(".myBtn", 1, {
      y: -100,
      opacity: 0,
    });

    gsap.to(".screen", 2, {
      y: -400,
      opacity: 0,
      ease: Power2.easeInOut,
      delay: 2,
    });

    gsap.from(".overlay", 2, {
      ease: Power2.easeInOut,
    });

    gsap.to(".overlay", 2, {
      delay: 2.6,
      top: "-110%",
      ease: Expo.easeInOut,
    });

    gsap.to(".overlay-2", 2, {
      delay: 3,
      top: "-110%",
      ease: Expo.easeInOut,
    });
    // gsap.from(".content", 2, {
    //   delay: 3.2,
    //   opacity: 0,
    //   ease: Power2.easeInOut,
    // });

    // gsap.to(".content", 2, {
    //   opacity: 1,
    //   y: -300,
    //   delay: 3.2,
    //   ease: Power2.easeInOut,
    // });
  }

  return (
    <>
      <div className="container">
        <div className="overlay">
          <p className="screen">INNTILL</p>
          <div className="intro">
            <div id="myBtn" onClick={() => fadeOut()} className="myBtn">
              EXPLORE
            </div>
          </div>
        </div>
        <div className="overlay-2"></div>
        <div ref={scrollRef} data-scroll-container>
          <div data-scroll data-scroll-speed="-8">
            <Header />
            <Banner setBannerElements={setBannerElements} />
            <Cursor cursorProps={cursorProps} />
          </div>
          <Main />
          <Contacts />
        </div>
      </div>
    </>
  );
}
