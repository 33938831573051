import { useEffect } from "react";

import SectionStyled from "./SectionStyled";

function Section(props) {
  useEffect(() => {
    console.log(props.img);
  });
  return (
    <SectionStyled className="section" id={props.last}>
      <div className="video">
        {props.video ? (
          <video autoPlay muted loop>
            <source src={props.video} type="video/mp4" />
          </video>
        ) : (
          <img src={props.img} alt="" />
        )}
      </div>
      <div className="box definition">
        <h3>{props.title}</h3>
        <p>{props.definition}</p>
        <div className="invisible">{props.gibber}</div>
      </div>
    </SectionStyled>
  );
}

export default Section;
