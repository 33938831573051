import styled from "styled-components";

const SectionStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 150px;
  height: 80vh;
  gap: 30px;

  .video {
    width: 100%;
    height: 90%;
    video {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 30px;
    }
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 30px;
    }
  }

  .definition {
    position: relative;
    width: 340px;
    max-height: 200px;
    margin-right: -20px;
    margin-top: -80px;
    border-radius: 30px;
    transform-style: preserve-3d;
    background: #ceff00;
    align-self: flex-end;
    padding: 20px 20px 20px 40px;
    color: #000;

    h3 {
      font-size: 1.4rem;
      width: 90%;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, 75px);
      transition: 0.5s;
      opacity: 1;
      z-index: 10;
      display: block;
    }
    p {
      display: block;
      line-height: 19px;
      font-size: 1rem;
      width: 80%;
      transform-style: preserve-3d;
      transform: translate3d(0, 0, 75px);
      transition: 0.5s;
      opacity: 1;
      z-index: 10;
    }

    .inivisible {
      color: #ceff00;
      display: inline-block;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 45%;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    height: auto;

    .video {
      min-height: 60%;
      height: 80%;
    }

    .definition {
      margin: 0;
      width: 100%;
      align-self: initial;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 60px;

    .definition {
      background-color: transparent;
      color: #fefefe;
      text-align: center;
      width: 80%;
      margin-top: 0;
      h3 {
        width: 100%;
        font-size: 24px;
        margin-bottom: 10px;
      }
      p {
        width: 100%;
        font-size: 20px;
        line-height: 35px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    margin-bottom: 60px;

    .definition {
      padding: 0;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 14px;
      }
    }

    .video {
      video {
        height: 215px;
      }
    }
  }
`;

export default SectionStyled;
