import styled from "styled-components";

export const ServicesParallax = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 150px 0 150px 0;
  #last-child {
    margin: 0;
  }

  .videos {
    width: 50%;
  }

  .serviceBigText {
    margin-left: 200px;
    border-radius: 10px;
    width: 35%;
    height: 600px;
  }
  .serviceBigText div {
    display: block;
    width: 100%;

    font-size: 10rem;
    font-weight: 900;
    font-style: normal;
    color: #fff;
    line-height: 8.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;

    &:last-child {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-stroke: 2.6px #fff;
      color: #000;
    }
  }
`;

export const ServiceParallaxRes = styled.div`
  display: flex;
  flex-direction: column;
  .serviceBigText {
    border-radius: 10px;
    width: 100%;
    margin: 0;
  }
  .serviceBigText div {
    display: block;
    width: 100%;
    font-size: 7rem;
    font-weight: 900;
    font-style: normal;
    color: #fff;
    line-height: 8.5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;

    &:last-child {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      -webkit-text-stroke: 2.6px #fff;
      color: #fff;
    }
  }

  .servicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
    margin-top: 10px;
    gap: 50px;
  }
  @media screen and (max-width: 600px) {
    .serviceBigText div {
      font-size: 6rem;
      text-align: center;
    }
  }

  @media screen and (max-width: 470px) {
    .servicesContainer {
      padding: 0;
    }
    .serviceBigText div {
      font-size: 4rem;
      text-align: center;
    }
  }
`;
